import React, { useEffect } from 'react';
import './styles.scss';
import { Spinner } from '@components';
import Select from '@components/Select';
import { useForm } from 'react-hook-form';
import { initGTMforButtons } from '@utils/initGTMforButtons';

const TechMapList = ({
  options,
  tablesOpt,
  loading,
  warning,
  onChangeOil,
  activeStep,
}) => {
  const [activeSection, setActiveSection] = React.useState('');
  const [activeSectionData, setActiveSectionData] = React.useState([]);
  const [priceOil, setPriceOil] = React.useState('');
  const [priceFilter, setPriceFilter] = React.useState('');

  const {
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const sections = React.useMemo(() => {
    const set = new Set([]);
    options && options.map((item) => set.add(item.category));
    const arr = Array.from(set);
    if (activeSection === '') {
      setActiveSection(arr[0]);
    }

    return arr;
  }, [options]);

  const characteristics = React.useMemo(() => {
    return (
      options &&
      options
        .filter((option) => option.category === activeSection)
        .map((item) => item.name)
    );
  }, [activeSection, options, onChangeOil]);

  useEffect(() => {
    tablesItem();
  }, [activeSection, loading, onChangeOil]);

  const tablesItem = () => {
    let filteredData = tablesOpt && tablesOpt[characteristics[0]].rows;
    setActiveSectionData(filteredData);
  };

  const handleChange = (el, type) => {
    if (type === 'oil') {
      setPriceOil(el?.price);
      onChangeOil(el.id);
    } else if (type === 'cabinFilter') {
      setPriceFilter(el?.price);
      onChangeOil(el.id);
    }
  };

  const returnPrice = (key, price, defaultPrice) => {
    switch (key) {
      case 'Масло двигуна ':
        return priceOil !== '' ? priceOil + ' грн' : defaultPrice;
      case 'Масло двигуна':
        return priceOil !== '' ? priceOil + ' грн' : defaultPrice;
      case 'Фільтр салону':
        return priceFilter !== '' ? priceFilter + ' грн' : defaultPrice;
      default:
        return price;
    }
  };

  const onSetActiveSection = item => {
    initGTMforButtons({
      eventType: 'custom_click',
      page_name: 'Сервіс - карти ТО',
      click_text: item,
    });
    setActiveSection(item);
  };

  const renderDataSection = () => {
    if (activeSection === 'Нормативи і вартість') {
      return (
        <div className="sectionData required">
          <div className="sectionData__content">
            <div className="sectionData__content-body">
              {activeSectionData.map((item, index) => (
                <div className="sectionData__item">
                  <div className="sectionData__col label">{item.label}</div>
                  <div className="sectionData__col value">{item.value}</div>
                </div>
              ))}
            </div>
            <div className="sectionData__content-footer">
              {warning
                ? warning
                : `Не є пропозицією укласти договір (офертою). Вартість робіт вказана
                згідно повідомлення дилера станом на 04.04.2022, є орієнтовною та
                не включає вартості робіт з демонтажу/встановлення наявних
                додаткових аксесуарів (що можуть потребуватися при ТО). При
                розрахунку застосовано ціни на оригінальні запчастини, які є
                рекомендованими (які дилерам рекомендовано не перевищувати). Ціни
                зазначено тільки для поточного модельного ряду, без врахування
                спеціальних акцій і пропозицій, які можуть діяти на момент
                замовлення робіт у відповідного дилерського центру Toyota.`}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Контрольно-оглядові роботи') {
      return (
        <div className="sectionData diagnostic">
          <div className="sectionData__content">
            <div className="sectionData__content-body">
              {activeSectionData.map((item, index) => (
                <div className="sectionData__item">
                  <div className="sectionData__col label">
                    {item['col-1']?.text}
                  </div>
                  <div className="sectionData__col value">
                    {item['col-2']?.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Регламентні перевірки і заміни') {
      return (
        <div className="sectionData regulatory">
          <div className="sectionData__content">
            <div className="sectionData__content-body">
              {activeSectionData.map((item, index) => (
                <div className="sectionData__item">
                  <div className="sectionData__col label">
                    {item['col-1']?.text}
                  </div>
                  <div className="sectionData__col value">
                    {item['col-2']?.text}
                  </div>
                  <div className="sectionData__col price">
                    {item['col-3']?.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    if (activeSection === 'Витратні матеріали') {
      return (
        <div className="sectionData materials">
          <div className="sectionData__content">
            <div className="sectionData__content-body">
              {activeSectionData &&
                activeSectionData.map((item, index) => (
                  <div
                    className="sectionData__item"
                    style={item['col-1']?.list && { marginBottom: 10 }}
                  >
                    <div className="sectionData__col label flex">
                      {item['col-1']?.text}

                      {item['col-1']?.text === 'Масло двигуна ' &&
                      item['col-1']?.list ? (
                        <div className="sectionData__col-list">
                          <Select
                            control={control}
                            options={item['col-1']?.list}
                            label={item['col-1']?.list[0].label}
                            // getOptionValue={(option) => option?.price}
                            // getOptionLabel={(option) => option?.label}
                            optValueKey="price"
                            optLabelKey="label"
                            selectedValueKey="price"
                            // defaultValue={
                            //   item['col-1']?.list.filter(
                            //     (el) =>
                            //       el.price ===
                            //       +item['col-3']?.text.replace(' грн', ''),
                            //   )[0]
                            // }
                            value={item['col-1']?.list.filter(
                              (option) =>
                                option.price ===
                                +item['col-3']?.text.replace(' грн', ''),
                            )}
                            menuPlacement="bottom"
                            // menuIsOpen
                            name={`oil${index * activeStep - activeStep}`}
                            isSearchable={false}
                            onChange={(el) => handleChange(el, 'oil')}
                            isClerable={false}
                          />
                        </div>
                      ) : null}
                      {item['col-1']?.text === 'Фільтр салону' &&
                      item['col-1']?.list ? (
                        <div className="sectionData__col-list">
                          <Select
                            control={control}
                            options={item['col-1']?.list}
                            label={item['col-1']?.list[0].label}
                            optValueKey="id"
                            optLabelKey="label"
                            selectedValueKey="id"
                            value={item['col-1']?.list.filter(
                              (option) =>
                                option.price ===
                                +item['col-3']?.text.replace(' грн', ''),
                            )}
                            menuPlacement="top"
                            name={`filter${index * activeStep + activeStep} `}
                            isSearchable={false}
                            onChange={(el) => handleChange(el, 'cabinFilter')}
                            isClerable={false}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="sectionData__col value">
                      {item['col-2']?.text}
                    </div>
                    <div className="sectionData__col price">
                      {/*{returnPrice(*/}
                      {/*  item['col-1']?.text,*/}
                      {/*  item['col-3']?.text,*/}
                      {/*  item['col-3']?.text,*/}
                      {/*)}*/}
                      {item['col-3']?.text}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="tech-map-list">
      <div className="list-of-sections">
        <ul className="section-list">
          {sections.map((section) => {
            const isActive = section === activeSection;

            return (
              <li
                key={section}
                className={`section-list__item ${
                  isActive ? 'section-list__item_active' : ''
                }`}
                onClick={() => onSetActiveSection(section)}
              >
                {section}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="characteristics">
        {loading ? (
          <Spinner />
        ) : (
          <ul className="characteristics-list">{renderDataSection()}</ul>
        )}
      </div>
    </div>
  );
};

export default TechMapList;
