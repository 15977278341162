// src/hooks/useMixpanel.ts
import { useEffect, useRef } from 'react';
import { trackEvent } from '@utils/mixpanel-new';

export const useMixpanel = (eventName: string, properties: Record<string, any>) => {
  const eventTracked = useRef(false);

  useEffect(() => {
    if (!eventTracked.current) {
      trackEvent(eventName, properties);
      eventTracked.current = true;
    }
  }, [eventName, properties]);
};

// export default useMixpanel;