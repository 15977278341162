import React from 'react';
import { BulletListItem } from '@components';
import { Option } from '../../../types';
import './styles.scss';
import { initGTMforButtons } from '../../../utils/initGTMforButtons';

type Props = {
  options: Option[];
};

const CarCharacteristicsList: React.FC<Props> = ({ options }) => {
  const [activeSection, setActiveSection] = React.useState('');

  const sections = React.useMemo(() => {
    const set = new Set<string>([]);
    options.map((item) => set.add(item.category));
    const arr = Array.from(set);
    setActiveSection(arr[0]);

    return arr;
  }, [options]);

  const characteristics = React.useMemo(() => {
    return options
      .filter((option) => option.category === activeSection)
      .map((item) => item.name);
  }, [activeSection, options]);

  const onSetActiveSection = async (item: string) => {
    initGTMforButtons({
      eventType: 'custom_click',
      page_name: 'Технічні характеристики',
      click_text: item,
    });
    setActiveSection(item);
  };

  return (
    <div className="car-characteristics-list">
      <div className="list-of-sections">
        <ul className="section-list">
          {sections.map((section) => {
            const isActive = section === activeSection;

            return (
              <li
                key={section}
                className={`section-list__item ${
                  isActive ? 'section-list__item_active' : ''
                }`}
                onClick={() => onSetActiveSection(section)}
              >
                {section}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="characteristics">
        <ul className="characteristics-list">
          {characteristics.map((option) => (
            <BulletListItem key={option} classes="characteristics-list__item">
              {option}
            </BulletListItem>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CarCharacteristicsList;
