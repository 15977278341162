import {Box} from "@mui/material";
import {Button, ModalCloseButton, SliderButton} from "@components/common/Buttons";
import React, {useRef, useState} from "react";
import {useGetAskModalInfoQuery} from "@store/modals/api";
import {Carousel} from "react-responsive-carousel";
import './style.scss'
import ListItem from "@components/Modals/TipsModal/ListItem";
import { initGTMforButtons } from '../../../utils/initGTMforButtons';
type TipsModalProps = {
    closeModal: () => void;
};
export type TipsDataType={
    id:string;
    description:string;
    image:string;
    position:number;
    published:number;
    title:string;
    video:string;
}
const TipsModal=({closeModal}:TipsModalProps)=>{
    const { isLoading, data: askModalInfo } = useGetAskModalInfoQuery({});
    const [activeSlide,setActiveSlide]=useState(1);
    const [currentRef,setCurrentRef]=useState(null)
    const [showControls,setShowControls]=useState(false);
    function handleChange(selectedIndex:number) {
        setActiveSlide(selectedIndex+1);
        // @ts-ignore
        currentRef &&  currentRef?.current.pause();
        setShowControls(false)
    }
    const onPrevNextClick = (onClick: () => void, direction: 'Попередня' | 'Наступна'): () => void => {
        return () => {
            initGTMforButtons({
                eventType: 'custom_click',
                page_name: 'Підказки',
                click_text: direction,
            });
            return onClick?.();
        };
    }
    return(
        <Box className={askModalInfo?.data?.length<2?'tipsModal modal hidePagination':'tipsModal modal'}>
            <ModalCloseButton onClick={() => {
                initGTMforButtons({
                    eventType: 'custom_click',
                    page_name: 'Підказки',
                    click_text: 'Закрити попап',
                });
                closeModal();
            }} />
            <div className={'content'}>
                <Carousel
                    showIndicators={false}
                    showStatus={false}
                    emulateTouch
                    width={'100%'}
                    swipeable={true}
                    showThumbs={false}
                    onChange={handleChange}
                    renderArrowPrev={(onClick, hasPrev) => (
                        <SliderButton
                            color={"whiteColor"}
                            width={64}
                            onClick={onPrevNextClick(onClick, 'Попередня')}
                            disabled={!hasPrev}
                            variant="left"
                            right={418}
                            chenvroneWidth={15}
                        />

                    )}
                    renderArrowNext={(onClick, hasNext) => (
                        <SliderButton
                            color={"whiteColor"}
                            width={64}
                            onClick={onPrevNextClick(onClick, 'Наступна')}
                            disabled={!hasNext}
                            variant="right"
                            right={222}
                            chenvroneWidth={15}
                        />
                    )}
                >
                    {askModalInfo?.data?.map((item:TipsDataType) => (
                        <ListItem item={item} activeSlide={activeSlide} length={askModalInfo.data.length} setCurrentRef={setCurrentRef} showControls={showControls} setShowControls={setShowControls}/>
                    ))}
                </Carousel>
            </div>
        </Box>
    )
}
export default TipsModal
