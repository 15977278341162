export const API_CONFIG = {
  // BASE_URL: "https://back.toyota-screen.com.vintagedev.com.ua/", // dev
  BASE_URL: "https://admin.rc.toyota.ua/", // prod
  // SERVICE_URL: "http://api.maintain.com.dev11.vintagedev.com.ua", // dev
  SERVICE_URL: "https://api.service.toyota.ua", // prod
};

export const MAPBOX_TOKEN = "pk.eyJ1IjoidG95b3Rhc2NyZWVuIiwiYSI6ImNsYnl2YzEwbzB3c3EzcXFvcHYycGU3Z3EifQ.gXNMUrVT_ZOxd9z-2cgcRg"

export const MIXPANEL_TOKEN = "6d8d66d65875bdd719b59c73e9ff1183"
