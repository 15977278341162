// src/utils/mixpanel.ts
import mixpanel from 'mixpanel-browser';

// const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
import { MIXPANEL_TOKEN } from "../constants";

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN, { debug: process.env.NODE_ENV === 'development' });
} else {
  console.warn('Mixpanel token is not defined');
}

export const trackEvent = (eventName: string, properties: Record<string, any>) => {
  try {
    if (MIXPANEL_TOKEN) {
      mixpanel.track(eventName, properties);
    } else {
      console.warn('Mixpanel token is not defined, event not tracked');
    }
  } catch (error) {
    console.error('Error tracking Mixpanel event:', error);
  }
};