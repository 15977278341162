import React from 'react';
import { Box } from '@mui/material';
import { Button, ModalCloseButton } from '@components';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import {useSelector} from "react-redux";
import {translationsDataArr} from "@store/translate/slice";
import getTranslateText from "@utils/getTranslateText";
import { initGTMforButtons } from '../../../utils/initGTMforButtons';
const cx = classNames.bind(styles);

type ConsultationModalProps = {
  closeModal: () => void;
  returnToConfiguratorMain?: () => void;
};

const ChangeCarModal = ({
  closeModal,
  returnToConfiguratorMain,
}: ConsultationModalProps) => {
  const translateArr = useSelector(translationsDataArr);
  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => {
        initGTMforButtons({
          eventType: 'custom_click',
          page_name: 'Змінити автомобіля',
          click_text: 'Закрити попап',
        });
        closeModal();
      }} />
      <div className={cx('modal__content')}>
        <p>{getTranslateText(translateArr, 'translate.admin.modal.text.change.car.title')}</p>
        <p>{getTranslateText(translateArr, 'translate.admin.modal.text.change.car.descr')}</p>
        <Button
          variant="primary"
          withoutBorder
          textFontSize={16}
          onClick={returnToConfiguratorMain}
        >
          {getTranslateText(translateArr, 'translate.admin.modal.text.change.car.btn.send')}
        </Button>
      </div>
    </Box>
  );
};

export default ChangeCarModal;
