import { useDispatch, useSelector } from 'react-redux';
import { Arrow, QRCode, Warranty } from '@assets/svg';
import { showConsultModal } from '@store/modals/slice';
import { THEME } from '@utils/theme';
import { Button, ArrowButton } from '../Buttons';
import { ButtonProps } from '../Buttons/Button';
import './Footer.scss';
import MostPopular from '@assets/svg/MostPopular';
import React from 'react';
import getTranslateText from '@utils/getTranslateText';
import { translationsDataArr } from '@store/translate/slice';
import { checkIs2sDealer } from '@utils/checkIs2sDealer';
import { useLocation } from 'react-router-dom';
import { checkPageForConsultationFor2s } from '@utils/checkPageForConsultationFor2s';
import { initGTMforButtons } from '@utils/initGTMforButtons';

type Props = {
  marginTop?: number | 'auto';
  marginLeft?: number | 'auto';
  pagination?: {
    currentPage: number;
    countOfPages: number;
    onClickPrevButton?: VoidFunction;
    onClickNextButton?: VoidFunction;
  };
  showPDFButton?: boolean;
  showSaveConfiguratorResult?: boolean;
  disabledSaveConfiguratorResult?: boolean;
  dataExtendedwarrantyButton?: any;
  showExtendedwarrantyButton?: boolean;
  showRateMyCarButton?: boolean;
  showCallConsultant?: boolean;
  children?: React.ReactNode;
  navigationButtons?: {
    backButtonProps?: ButtonProps;
    nextButtonProps?: ButtonProps;
  };
  backButtonIsFirst?: {
    backButtonProps?: ButtonProps;
  };
  showConfigureModelButton?: boolean;
  callConsultationClick?: () => void;
  saveConfiguratorResult?: () => void;
  getPdf?: () => void;
  callExtendedWarrantyClick?: () => void;
  callRateMyCarClick?: () => void;
  pdfBtnHeight?: string | number;
};

const Footer = ({
  marginTop,
  marginLeft,
  pagination,
  showPDFButton = false,
  dataExtendedwarrantyButton,
  showExtendedwarrantyButton = false,
  showRateMyCarButton = false,
  showCallConsultant = false,
  children,
  navigationButtons,
  showConfigureModelButton = false,
  callConsultationClick,
  callExtendedWarrantyClick,
  callRateMyCarClick,
  backButtonIsFirst,
  pdfBtnHeight = 64,
  showSaveConfiguratorResult,
  disabledSaveConfiguratorResult,
  saveConfiguratorResult,
  getPdf,
}: Props) => {
  const dispatch = useDispatch();
  const translateArr = useSelector(translationsDataArr);
  const url = useLocation();

  const isNeedPagination = pagination && pagination?.countOfPages > 1;

  const ExtendedwarrantyIcon = () => (
    <img
      src={dataExtendedwarrantyButton?.icon}
      alt=""
      style={{ width: 30, height: 30, objectFit: 'cover' }}
      />
  )
  return (
    <footer className="footer" style={{marginTop, marginLeft}}>
      {backButtonIsFirst && (
        <div className="navigation-buttons first">
          {backButtonIsFirst.backButtonProps && (
            <Button
              type="rounded"
              width={'auto'}
              height={64}
              iconPosition="left"
              backgroundColor={{
                default: THEME.colors.white,
                active: THEME.colors.primary_black,
                disabled: THEME.colors.gray_4,
              }}
              textColor={{
                default: THEME.colors.gray_7,
                active: THEME.colors.white,
                disabled: THEME.colors.white,
              }}
              icon={(color: any) => <Arrow color={color} direction="left" />}
              {...backButtonIsFirst.backButtonProps}
            >
              {backButtonIsFirst.backButtonProps.children ||
                getTranslateText(translateArr, 'translate.admin.button.prev')}
            </Button>
          )}
        </div>
      )}
      {isNeedPagination ? (
        <div className="pagination">
          <ArrowButton
            variant="left"
            onClick={() => {
              initGTMforButtons({
                eventType: 'custom_click',
                click_text: 'Пагінація - назад',
              });
              //@ts-ignore
              pagination.onClickPrevButton();
            }}
            disabled={pagination.currentPage === 1}
          />
          <div className="pagination-pages">
            <span className="pagination-pages__current-page">
              {pagination.currentPage}
            </span>
            <span className="pagination-pages__count-of-pages">
              {' '}
              &nbsp;/&nbsp;{' ' + pagination.countOfPages}
            </span>
          </div>
          <ArrowButton
            variant="right"
            onClick={() => {
              initGTMforButtons({
                eventType: 'custom_click',
                click_text: 'Пагінація - вперед',
              });
              //@ts-ignore
              pagination.onClickNextButton();
            }}
            disabled={pagination.currentPage === pagination.countOfPages}
          />
        </div>
      ) : null}
      <div
        className={
          backButtonIsFirst && navigationButtons?.nextButtonProps
            ? 'footer-content configurator-footer'
            : 'footer-content'
        }
      >
        {showPDFButton ? (
          <Button
            variant="secondary"
            width={'auto'}
            height={pdfBtnHeight}
            onClick={getPdf}
            withoutBorder
            icon={(color) => <QRCode color={color} />}
            backgroundColor={'transparent'}
            textColor={{
              default: THEME.colors.primary_black,
              active: THEME.colors.gray_7,
              disabled: THEME.colors.gray_6,
            }}
            iconColor={{
              default: THEME.colors.primary_black,
              active: THEME.colors.gray_7,
              disabled: THEME.colors.gray_6,
            }}
          >
            {getTranslateText(translateArr, 'translate.admin.footer.btn.pdf')}
          </Button>
        ) : null}
        {showExtendedwarrantyButton ? (
          <Button
            variant="secondary"
            width={'auto'}
            height={pdfBtnHeight}
            onClick={callExtendedWarrantyClick}
            withoutBorder
            icon={<ExtendedwarrantyIcon />}
            marginLeft={40}
            backgroundColor={'transparent'}
            textColor={{
              default: THEME.colors.primary_black,
              active: THEME.colors.gray_7,
              disabled: THEME.colors.gray_6,
            }}
            iconColor={{
              default: THEME.colors.primary_black,
              active: THEME.colors.gray_7,
              disabled: THEME.colors.gray_6,
            }}
          >
            {dataExtendedwarrantyButton?.title}
          </Button>
        ) : null}
        {showSaveConfiguratorResult && (
          <Button
            variant="secondary"
            width={303}
            height={64}
            onClick={saveConfiguratorResult}
            marginLeft={40}
            disabled={disabledSaveConfiguratorResult}
            textColor={{
              default: THEME.colors.primary_black,
              active: THEME.colors.white,
              disabled: THEME.colors.gray_6,
            }}
            backgroundColor={{
              default: THEME.colors.transparent,
              active: THEME.colors.primary_black,
              disabled: THEME.colors.transparent,
            }}
          >
            {getTranslateText(
              translateArr,
              'translate.admin.footer.btn.save.result',
            )}
          </Button>
        )}
        {showRateMyCarButton ? (
          <Button
            variant="secondary"
            width={303}
            height={64}
            onClick={callRateMyCarClick}
            marginLeft={40}
          >
            Оцінити мій автомобіль
          </Button>
        ) : showConfigureModelButton ? (
          <Button
            variant="secondary"
            width={303}
            height={64}
            onClick={() => {}}
            marginLeft={40}
          >
            Конфігурувати модель
          </Button>
        ) : null}

        {navigationButtons ? (
          <div className="navigation-buttons">
            {navigationButtons.backButtonProps && (
              <Button
                notAddedGTM={true}
                type="rounded"
                // width={258}
                height={64}
                iconPosition="left"
                backgroundColor={{
                  default: THEME.colors.white,
                  active: THEME.colors.primary_black,
                  disabled: THEME.colors.gray_4,
                }}
                textColor={{
                  default: THEME.colors.gray_7,
                  active: THEME.colors.white,
                  disabled: THEME.colors.white,
                }}
                icon={(color: any) => <Arrow color={color} direction="left" />}
                {...navigationButtons.backButtonProps}
              >
                {navigationButtons.backButtonProps.children ||
                  getTranslateText(translateArr, 'translate.admin.button.prev')}
              </Button>
            )}
            {navigationButtons.nextButtonProps && (
              <Button
                notAddedGTM={true}
                type="rounded"
                width={'auto'}
                height={64}
                iconPosition="right"
                backgroundColor={{
                  default: THEME.colors.primary_black,
                  active: 'rgba(40, 40, 48, 0.8)',
                  disabled: THEME.colors.gray_4,
                }}
                textColor={{
                  default: THEME.colors.white,
                  active: THEME.colors.white,
                  disabled: THEME.colors.white,
                }}
                icon={(color: any) => <Arrow color={color} />}
                {...navigationButtons.nextButtonProps}
              >
                {navigationButtons.nextButtonProps.children ||
                  getTranslateText(
                    translateArr,
                    'translate.admin.button.apply',
                  )}
              </Button>
            )}
          </div>
        ) : null}

        {/* <div style={{ width: 606, height: 1, marginLeft: 48 }} /> */}
        {/* {showCallConsultant ? (
          <>
            <Button
              variant="primary"
              width={303}
              height={64}
              onClick={() =>
                callConsultationClick
                  ? callConsultationClick()
                  : dispatch(showConsultModal())
              }
              marginLeft={24}
            >
              {checkIs2sDealer() && checkPageForConsultationFor2s(url.pathname)
                ? getTranslateText(
                    translateArr,
                    'buttons.get.consultant.modal.btn',
                  )
                : getTranslateText(
                    translateArr,
                    'buttons.consultant.modal.btn',
                  )}
            </Button>
          </>
        ) : null} */}
      </div>
      {children}
    </footer>
  );
};

export default Footer;
